import * as whintegration from '@mod-system/js/wh/integration';
// import whBase from '@mod-system/js/compat/base';

export function triggerErrorDialog(title, text, errorDetails) {
  if (errorDetails)
    console.error(errorDetails);

  window.tredinApp.alert(text, title);
}

export function triggerGeneralErrorDialog(err) {
  triggerErrorDialog('Foutmelding', 'Er ging iets mis bij het verwerken van de actie. Probeer het opnieuw of neem contact met ons op als het probleem zich blijft voordoen.', err);
}

export function timeoutPromise(ms) {
  // if (whBase.debug.noloader === true)
  //   ms = 1;

  return new Promise(resolve => setTimeout(resolve, ms));
}

export async function addMinimumResolveTime(promise, time) {
  let timeout = timeoutPromise(time);
  let result = await promise;
  await timeout;
  return result;
}

export function setupPageBeforeAnimationEvents() {
  if (!whintegration.config.site.pages)
    return;

  function dispatch(page) {
    let event = new CustomEvent('beforepageanimation', { detail: { page: page } });
    document.dispatchEvent(event);
  }

  for (const page of whintegration.config.site.pages) {
    window.tredinApp.onPageBeforeAnimation(page.tag, () => dispatch(page.tag));
  }

  /* to subscribe to a page load event:
  document.addEventListener('beforepageanimation', (evt) => {
    if (evt.detail.page === 'add_expedition') {
      // ...
    }
  });
  */
}
