// import whBase from '@mod-system/js/compat/base';
import * as dompack from 'dompack';
import RPCFormBase from '@mod-publisher/js/forms/rpc';

import $ from 'jquery';
import 'hideshowpassword/css/example.wink.css';
require('hideshowpassword');

// import TredinForms from '../../forms/forms';

dompack.onDomReady(() => {
  $('input[type="password"]').hideShowPassword({ show: false,
                                                 innerToggle: true,
                                               });
});

class RegistrationForm extends RPCFormBase {
  constructor(node) {
    super(node);
    this.node = node;

    // if (whBase.debug['debug'])
    //   this.invokeRPC('debugprefill');
  }
}

dompack.register("#registration", form => new RegistrationForm(form));
