import './login.scss';

// WebHare
import * as whintegration from '@mod-system/js/wh/integration';
// import WhBase from '@mod-system/js/compat/base';
import WRDAuthenticationProvider from '@mod-wrd/js/auth';
import JSONRPC from '@mod-system/js/net/jsonrpc';
import * as dompack from 'dompack';

// vendor
import $ from 'jquery';
import swal from 'sweetalert2';

dompack.onDomReady(() => {
  if (!document.documentElement.classList.contains('page-login'))
    return;

  $('#loginform').submit((evt) => {
    evt.preventDefault();
    tryLogin($('#login-email').val(), $('#login-password').val());
  });

  // if (WhBase.debug['debug'] === true) {
  //   dompack.qS('input[name="login"]').value = 'wouter@scania.nl';
  //   dompack.qS('input[name="password"]').value = 'a';
  // }

  initLoginPage();
});

async function tryLogin(email, password) {
  let rpc = new JSONRPC();

  let loginResult = await rpc.async('Login', email, password);
  if (loginResult.success) {
    location.href = whintegration.config.obj.onlogin;
    return;
  }

  console.error(loginResult.error);

  swal({
    title: 'Kon niet inloggen',
    text: 'Ongeldig emailadres en/of wachtwoord ingevuld.',
    type: 'error',
  });
}

function initLoginPage() {
  dompack.qS('.wh-wrdauth-form').classList.add('ww-grid');

  if (document.documentElement.dataset.page === 'login') {
    let h1 = document.createElement('h1');
    h1.textContent = "Inloggen";
    dompack.qS('.wh-wrdauth-form').prepend(h1);

    if (dompack.debugflags['debug']) {
      dompack.qS('#login-username').value = 'wouter@beta.webhare.net';
      dompack.qS('#login-password').value = 'secret123';
    }
  } else if (document.documentElement.dataset.page === 'forgotpassword') {
    const emailinput = dompack.qS('input[name="email"]');
    if(emailinput) {
      emailinput.type = "email";
    }
  } else if (document.documentElement.dataset.page === 'setpassword') {
    let h1 = document.createElement('h1');
    h1.textContent = "Wachtwoord instellen";
    dompack.qS('.wh-form').prepend(h1);

    dompack.qS('.wh-wrdauth-setpassword__done').textContent = 'Je hebt je account succesvol geactiveerd en bent automatisch ingelogd.';
  }
}
