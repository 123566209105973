import '@mod-publisher/js/forms';
// import whBase from '@mod-system/js/compat/base';
import * as dompack from 'dompack';
import * as whintegration from '@mod-system/js/wh/integration';

import $ from 'jquery';

dompack.onDomReady(() => {
  let isEditMode = document.documentElement.classList.contains('page-form-edit-mode');

  if (!isEditMode)
    $('input[name*="generateid"]').val('<wordt gegenereerd>');

  if (whintegration.config.obj.printoption && whintegration.config.obj.printoption == 'id') {
    $('div[data-wh-form-pagerole="thankyou"]').append(`<a target="_blank" id="printbutton" href="${whintegration.config.obj.printurl}" class="button">Print gegenereerde labelnummer</a>`);

    dompack.qS('.wh-form').addEventListener('wh:form-response', function(event) {
      if (event.detail && event.detail.result && event.detail.result.resultsguid) {
        let url = new URL($('#printbutton').attr('href'));
        url.searchParams.set('resultguid', event.detail.result.resultsguid);
        $('#printbutton').attr('href', url.toString());
      }
    });
  }

  if (isEditMode) {
    $('.header').remove();
    $('div[data-wh-form-pagerole="thankyou"]').append(`<p>Sluit dit venster om terug te keren naar de beheerapplicatie.</p>`);
  }

  $('[id$="colloafm"]').each(function() {
    $(this).attr('type', 'number').closest('.wh-form__fieldline').append('<span class="wh-form__suffix">cm</span>');
    if ($(this).get(0).value === '') {
      $(this).val('0');
    }
  });

  $('[id$="weight"]').each(function() {
    $(this).attr('type', 'number').closest('.wh-form__fieldline').append('<span class="wh-form__suffix">kg</span>');
    if ($(this).get(0).value === '') {
      $(this).val('0');
    }
  });
});
