import * as dompack from "dompack";
import getTid from "@mod-tollium/js/gettid";
import FormBase from "@mod-publisher/js/forms/formbase";
import "./canvasupload.scss";

import SignaturePad from 'signature_pad';

export default class CanvasUpload
{
  constructor(node, options)
  {
    // Initialize node references
    this.node = node;
    this.canvasNode = dompack.qS(node, ".canvasupload__input");
    let rect = this.canvasNode.getBoundingClientRect();
    this.canvasNode.width = rect.width;
    this.canvasNode.height = rect.height;
    this.canvasContext = this.canvasNode.getContext("2d");
    this.imgNode = dompack.qS(node, ".canvasupload__img");
    this.clearNode = dompack.qS(node, ".canvasupload__clear");

    let hasUploadValue = this.node.dataset.canvasUploadValue !== '';

    // Field initialization
    this.isRequired = !hasUploadValue && this.node.dataset.canvasUploadRequired == "true";
    this._updateEnabledStatus(this.node.dataset.canvasUploadDisabled != "true");
    this.node.whFormsApiChecker = () => this._check();
    this.node.whUseFormGetValue = true;
    this.node.addEventListener("wh:form-getvalue", event => this.getValue(event));

    // Initialize fieldgroup
    this._fieldgroup = dompack.closest(this.node,".wh-form__fieldgroup");
    if (this._fieldgroup)
    {
      this.node.dataset.whFormEnableListener = true;

      // ADDME: for now, we're not worrying about dynamic disabling/requiring
      // this.node.addEventListener("wh:form-enable", event => this._handleEnable(event));
      // this.node.addEventListener("wh:form-require", event => this._handleRequire(event));
    }

    // Init canvas drawing mechanism
    let canvasOptions = {
      onBegin: this.onStartDraw.bind(this),
    };

    this.signaturePad = new SignaturePad(this.canvasNode, canvasOptions);

    // Clear the field on clicking the clear button
    this.clearNode.addEventListener("click", event => this._clear(event));

    // If there already is a value, show it
    if (this.node.dataset.canvasUploadValue) {
      this.setValue(this.node.dataset.canvasUploadValue);
      this.node.classList.add('canvasupload--disabled');
      this.canvasNode.style.display = 'none';
      this.imgNode.src = this.node.dataset.canvasUploadValue;
    }
    else {
      this._clear();
    }
  }

  onStartDraw() {
    this.handleCanvasDrawn();
  }

  _clear(event)
  {
    if (event)
      dompack.stop(event);

    // Clear state
    this.isDirty = false;
    this.hasValue = false;

    // Clear drawing
    this.signaturePad.clear();

    // Hide clear button
    this.clearNode.style.display = "none";
  }

  _check()
  {
    if (this.isRequired && !this.hasValue && !this.isDirty)
      FormBase.setFieldError(this.node, getTid("publisher:site.forms.commonerrors.required"), { reportimmediately: false });
    else
      FormBase.setFieldError(this.node, "", { reportimmediately: false });
  }

  // _handleEnable(event)
  // {
  //   dompack.stop(event);
  //   this._updateEnabledStatus(event.detail.enabled);
  // }

  // _handleRequire(event)
  // {
  //   dompack.stop(event);
  //   this.isRequired = event.detail.required;
  // }

  handleCanvasDrawn()
  {
    // Set dirty
    this.isDirty = true;

    // Show clear button
    this.clearNode.style.display = this.isEnabled ? "" : "none";
  }

  _updateEnabledStatus(enabled)
  {
    if (enabled != this.isEnabled)
    {
      this.isEnabled = enabled;
      this.clearNode.style.display = this.isEnabled && (this.isDirty || this.hasValue) ? "" : "none";

      //ADDME: Enable/disable canvas drawing stuff based on this.isEnabled
    }
  }

  setValue(imgurl)
  {
    // Set state
    this.isDirty = false;
    this.hasValue = true;
  }

  getValue(event)
  {
    event.preventDefault();
    event.stopPropagation();

    if (this.hasValue)
    {
      // We have a set value
      event.detail.deferred.resolve(); // ADDME: this works as long as we're not allowing edits
    }
    else if (this.isDirty)
    {
      // We have a drawing on canvas
      event.detail.deferred.resolve({ link: this.canvasNode.toDataURL(), filename: this.node.dataset.whFormName + ".png" });
    }
    else
    {
      // The field is empty
      event.detail.deferred.resolve(null);
    }
  }
}
