// WebHare
import '@mod-publisher/js/richcontent/all'; //support rich content (video, images)
import '@mod-system/js/wh/integration'; //activate WebHare features
import * as dompack from 'dompack';
// import whBase from '@mod-system/js/compat/base';
import WRDAuthProvider from '@mod-wrd/js/auth';

// main CSS
import 'swiper/dist/css/swiper.min.css';
import 'font-awesome/css/font-awesome.min.css';
import './tredin.scss';

// vendor
import $ from 'jquery';
import swal from 'sweetalert2';

// internal
// import Login from './shared/js/login';
import * as util from './shared/js/utilities';

// pages/components
import './forms/forms';
import './pages/editableform/editableform';
import './pages/login/login';
import './pages/registration/registration';

dompack.onDomReady(() => {
  // let login = new Login;
  // login.setup();

  $('.js-logout').click((evt) => {
    evt.preventDefault();

    swal({
      title: 'Uitloggen',
      text: "Weet je zeker dat je uit wilt loggen?",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ja, log me uit',
      cancelButtonText: 'Annuleren',
    }).then((result) => {
      if (result.value) {
        WRDAuthProvider.getDefaultAuth().logout();
      }
    })
  });

  // window.addEventListener("wh:form-submitted", onFormSubmit);
});

async function mainInit() {

  // await util.addMinimumResolveTime(login.handleInitialLoginState(), 1000);

  // $('.siteloader').addClass('hide');

  // // window.setTimeout(() => { window.tredinApp.popup('.popup-registration', false, true); }, 200);

  // if (whBase.debug['debug'] === true) {
  //   debug();
  // }
}

function debug() {
  // dompack.qS('#login-login').value = 'wouter@webwerf.nl';
  // dompack.qS('#login-password').value = 'a';
  // $('#loginform').trigger('submit');
}
