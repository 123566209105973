import * as dompack from "dompack";

import "./deny.scss";

export default class DenyField {
  constructor(node, options) {
    if (node.getAttribute('data-no-submitter') === 'true') {
      dompack.closest(node, '.wh-form__fieldgroup').style.display = 'none';
      return;
    }

    this.elDenyCheckbox = node.querySelector('input[type="checkbox"]');
    this.elDenyReason = node.querySelector('textarea');

    node.addEventListener("wh:form-getvalue", event => this.getValue(event));

    this.elDenyCheckbox.addEventListener('change', (evt) => {
      this.elDenyReason.disabled = !evt.target.checked;
      this.elDenyReason.required = evt.target.checked;
    });
  }

  setValue() {
  }

  getValue(event) {
    event.preventDefault();
    event.stopPropagation();

    let val = { reason: '' };
    if (!this.elDenyReason.disabled)
      val.reason = this.elDenyReason.value;

    event.detail.deferred.resolve(val);
  }
}
