import './forms.scss';

import '@mod-publisher/js/forms';

import * as dompack from 'dompack';
import * as dombusy from '@webhare/dompack/impl/busy';
import * as upload from '@mod-system/js/compat/upload';
// import ParsleyForm from '@webhare/formsapi-parsleyjs';
import UploadField from '@mod-publisher/js/forms/fields/upload';
import ImgEditField from '@mod-publisher/js/forms/fields/imgedit';
import RTDField from '@mod-publisher/js/forms/fields/rtd';

import CanvasUploadField from './canvasupload/canvasupload';
import DenyField from '../../../formcomponents/deny/deny';

import * as forms from '@mod-publisher/js/forms';
forms.setup( { validate: true } );

import $ from 'jquery';
// require('parsleyjs');

export class ImageEditor extends ImgEditField {
  constructor(node) {
    super(node);
  }

  async selectFile(evt)
  {
    if(!this._getEnabled())
      return;

    evt.preventDefault();
    let files = await upload.selectFiles();
    let lock = dompack.flagUIBusy();
    $('#loader').addClass('show');
    this.uploadFile(files, lock);
    await dombusy.waitUIFree();
    $('#loader').removeClass('show');
  }
}

dompack.register(".wh-form__upload", node => new UploadField(node));
dompack.register(".wh-form__imgedit", node => new ImageEditor(node));
dompack.register(".wh-form__rtd", node => new RTDField(node, rtdopts));
// dompack.register('form.wh-form', form => new ParsleyForm(form));
dompack.register(".canvasupload", node => new CanvasUploadField(node));
dompack.register(".ff-deny", node => new DenyField(node));
